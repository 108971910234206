@font-face {
  font-family: "Gilroy-Thin";
  src: local("Gilroy-Thin"), url(./fonts/Gilroy-Thin.ttf) format("truetype");
}

@font-face {
  font-family: "Gilroy-UltraLight";
  src: local("Gilroy-UltraLight"),
    url(./fonts/Gilroy-UltraLight.ttf) format("truetype");
}

@font-face {
  font-family: "Gilroy-Light";
  src: local("Gilroy-Light"), url(./fonts/Gilroy-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Gilroy-Regular";
  src: local("Gilroy-Regular"),
    url(./fonts/Gilroy-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Gilroy-Medium";
  src: local("Gilroy-Medium"), url(./fonts/Gilroy-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Gilroy-Semibold";
  src: local("Gilroy-Semibold"),
    url(./fonts/Gilroy-Semibold.ttf) format("truetype");
}

@font-face {
  font-family: "Gilroy-Bold";
  src: local("Gilroy-Bold"), url(./fonts/Gilroy-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Gilroy-Extrabold";
  src: local("Gilroy-Extrabold"),
    url(./fonts/Gilroy-Extrabold.ttf) format("truetype");
}

@font-face {
  font-family: "Gilroy-Black";
  src: local("Gilroy-Black"), url(./fonts/Gilroy-Black.ttf) format("truetype");
}

div * {
  box-sizing: border-box;
}
a {
  all: unset;
}


body {
  height: 100vh;
  overflow: auto;
  background-color: black;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
